<template>
    <div class="objetivo-transversal-plan" v-if="indicadores.length > 0" :open="open">

        <h3 class="encabezado-indicador-transversal" @click="open = !open">Indicadores Transversales</h3>
        <ul class="objetivos-list" v-if="open">
            <li v-for="(data, key) in indicadores(data.id)" :key="key">
                <router-link :to="$t('enlaces.indicador') + '/' + data.id">{{ data.nombre }}</router-link>
            </li>
        </ul>

    </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
    name: 'objetivo-transversal-plan',
    props: ['data'],
    mounted() {
        //console.log("data", this.data);
    },
    data: () => ({
        open: true
    }),
    computed: {
        ...mapGetters({
            indicadores: 'getIndicadoresByEje'
        })
    }
}
</script> 

<style>
/* encabezado-indicador-transversal oblicuo */
.encabezado-indicador-transversal {
    font-size: 1.4em;
    font-weight: 600;
    font-style: italic;
    color: #2b2b2b;
    /* text-transform: uppercase; */
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
    margin-top: 1rem;
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding-left: 1rem;
}
</style>