<template>
    <div class="wrapper">

        <section class="encabezado -section" data="plan-estrategico">

            <h2 class="title -white">Plan Estratégico</h2>

            <h3 class="subtitle">
                ¿Cómo queremos que sea Madrid?
            </h3>

        </section>

        <nav class="breadcrumb">
            <div class="container row-start">
                <router-link to="/">Inicio</router-link>
                <router-link :to="$route.fullPath">Compromisos de Gobierno</router-link>
                <router-link :to="$route.fullPath">¿Cómo queremos que sea Madrid?</router-link>
            </div>
        </nav>

        <a @click="back" class="back">Volver</a>

        <section class="cuadro-resumen-banner -section">
            <div class="container">
                <nav class="row-center">
                    <router-link :to="$t('enlaces.compromisos')">¿Cómo queremos que sea Madrid?</router-link>
                    <router-link :to="$t('enlaces.planoperativo')">¿Cómo vamos a conseguirlo?</router-link>
                    <router-link :to="$t('enlaces.indicecumplimiento')">Índice de cumplimiento</router-link>
                </nav>



                <article class="cuadro row-between">


                    <template>
                        <div class="texto">
                            <h2 class="title -left"><strong>Plan Estratégico</strong> ¿Cómo queremos que sea Madrid?
                            </h2>
                            <p>Queremos que Madrid sea la mejor ciudad para vivir y disfrutar, y para ello definimos 5
                                ejes y 28 objetivos estratégicos, para cuya medición se identifican indicadores
                                estratégicos.</p>
                            <a target="_blank"
                                href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa_Estrat%C3%A9gico_2019-2023.pdf"
                                class="pdf">Mapa Estratégico 2019 - 2023</a>
                        </div>


                        <div class="imagen row-end">
                            <a target="_blank"
                                href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa_Estrat%C3%A9gico_2019-2023.pdf"
                                class="ver-mas">
                                <img src="/img/ver-mas.svg" alt="">
                            </a>
                            <a target="_blank"
                                href="https://www.madrid.es/UnidadesDescentralizadas/Calidad/Observatorio_Ciudad/02_SG_Estrategica/Ficheros/Mapa_Estrat%C3%A9gico_2019-2023.pdf">
                                <img src="/img/imagen-plan-estrategico.png" title="Mapa estratégico 2019 - 2023"
                                    alt="Mapa estratégico 2019 - 2023">
                            </a>
                        </div>

                    </template>

                </article>

            </div>
        </section>



        <section class="plan-estrategico-banner -section">
            <div class="container">
                <h2 class="title -white">El Plan Estratégico 2019 - 2023 en cifras</h2>
                <div class="stats row-center" v-if="dominios">
                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="dominios.ejes.length" :separator="'.'"></count-to>
                        </strong>
                        <span>Ejes estratégicos</span>
                    </article>

                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="dominios.objetivos.length" :separator="'.'"></count-to>
                        </strong>
                        <span>Objetivos estratégicos</span>
                    </article>

                    <article class="stat -big">
                        <strong>
                            <count-to :endVal="dominios.indicadoresejes.length" :separator="'.'"></count-to>
                        </strong>
                        <span>Indicadores estratégicos</span>
                    </article>

                </div>
            </div>
        </section>

        <section class="title-container -section">
            <div class="container">
                <h2 class="title -left -primary">Queremos que Madrid sea:</h2>
            </div>
        </section>


        <template v-if="dominios.ejes">
            <eje-plan-estrategico v-for="(data, key) in dominios.ejes" :data="data" :pos="key"
                :key="key"></eje-plan-estrategico>
        </template>




        <banner-ayudamos :data="['que-es-pog', 'plan-estrategico', 'observatorio-ciudad']"></banner-ayudamos>

    </div>
</template>


<script>

import ejeplanestrategico from '@/components/parts/eje-plan-estrategico';

import { mapGetters } from 'vuex';
import { mapActions } from 'vuex'

import router from '@/router';

export default {
    name: 'compromiso-gobierno',
    mounted() {
        this.requestHomeData();
        this.requestDominios();
    },
    computed: {
        ...mapGetters({
            dominios: 'getDominios'
        }),
    },
    components: {
        'eje-plan-estrategico': ejeplanestrategico
    },
    methods: {
        ...mapActions(['requestHomeData', 'requestDominios']),
        back() {
            router.go(-1);
        }
    }
}
</script>