<template>
    <div class="objetivo-estrategico-plan" :open="open">

        <h3 @click="open = !open">{{ data.nombre }}</h3>
        <ul class="objetivos-list" v-if="open">
            <li v-for="(data, key) in indicadores(data.id)" :key="key">
                <router-link :to="$t('enlaces.indicador') + '/' + data.id">{{ data.nombre }}</router-link>
            </li>
        </ul>

    </div>
</template>


<script>

import { mapGetters } from 'vuex'

export default {
    name: 'objetivo-estrategico-plan',
    props: ['data'],
    data: () => ({
        open: false
    }),
    computed: {
        ...mapGetters({
            indicadores: 'getIndicadoresByObj'
        })
    }
}
</script>