<template>
    <section class="eje-wrapper -section">
        <div class="container">

            <header class="header-eje row-center">
                <h2 class="title-eje">
                    <span>{{ data.nombre }}</span>
                </h2>
                <h3 class="contador">
                    <span class="numero">{{ data.objetivos }}</span>
                    <span class="texto">Objetivos estratégicos</span>
                </h3>

                <h3 class="contador">
                    <span class="numero">{{ data.indicadores }}</span>
                    <span class="texto">Indicadores estratégicos</span>
                </h3>
            </header>

            <main class="main-eje row-center">

                <div class="image" :style="`background:url(/img/eje-${pos + 1}.jpg) center/cover no-repeat`"></div>

                <div class="objetivos-container">

                    <h2>Objetivos estratégicos</h2>

                    <objetivo v-for="(data, key) in objetivos(data.id)" :data="data" :key="key"></objetivo>
                    <objetivotransversal v-for="(data, key) in ejes(data.id)" :data="data" :key="'transversales' + key">
                    </objetivotransversal>

                </div>

            </main>

        </div>
    </section>
</template>

<script>

import objetivo from '@/components/parts/objetivo-estrategico-plan';
import objetivotransversal from '@/components/parts/objetivo-transversal-plan';

import { mapGetters } from 'vuex';

export default {
    name: 'eje-plan-estrategico',
    props: ['data', 'pos'],
    mounted() {
    },
    computed: {
        ...mapGetters({
            objetivos: 'getObjetivos',
            ejes: 'getEjes'
        })
    },
    components: {
        'objetivo': objetivo,
        'objetivotransversal': objetivotransversal
    }
}
</script>